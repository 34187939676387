import React, {Component} from 'react';
import Interceptors from '../components/utils/interceptors';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import './application.css';

import 'bootstrap/dist/js/bootstrap';
import 'react-notifications/lib/notifications.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'aos/dist/aos.css'

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import 'velocity-animate';
import configureStore, {history} from '../components/store/configureStore';

import HomePage from '../components/components/containers/HomePage';

export default class Home extends Component {
  render() {
    const { store, history } = this.props;

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <HomePage />
        </ConnectedRouter>
      </Provider>
    );
  }
}

Home.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const store = configureStore();
Interceptors.setupInterceptors(store);

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Home history={history} store={store} />,
    document.getElementById('landing')
  )
});
