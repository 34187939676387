import React, {useRef, useState} from 'react';
import {doTrack} from '../../utils/utils';
import qubeLogo from '../../images/home/qubeLogo.svg';
import landingImage01 from '../../images/home/bg_hero.png';
import landingImg_1 from '../../images/home/growing.png';
import landingImg_2 from '../../images/home/slack.png';
import landingImg_3 from '../../images/home/roomName.png';
import landingHero from '../../images/home/office.png';
// import VisibilitySensor from 'react-visibility-sensor';
import {useInView} from 'react-intersection-observer';

import {newsletterSubscription} from '../../utils/api.utils';

/**
 * Actual Authentication happens via Slack.
 * Once authenticated the user is available via devise.
 * The office.html.erb file contains a div with the currentUserId.
 * If that field has a valid value we can assume we have a user.
 */
export const HomePage = () => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  useState(() => {
    doTrack('Home Page loaded: qube.work');
  }, []);
  const [showLogo, setShowLogo] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newsletterSuccess, setNewsletterSuccess] = useState(false);
  const [newsletterError, setNewsletterError] = useState(false);
  const [email, setEmail] = useState('');
  const onChange = (visible) => {
    setShowLogo(!visible);
    doTrack('Home page toolbar visible', { visible });
  };
  let scrollRef = useRef();
  let topRef = useRef()
  const subscribeToNewsletter = (e) => {
    e.preventDefault();
    setNewsletterSuccess(false);
    setNewsletterError(false);
    return newsletterSubscription(email)
      .then(() => {
        setNewsletterSuccess(true);
        setEmail('');
      })
      .catch((resp) => {
        setErrorMessage(resp.data.errors);
        setNewsletterError(true)
      });
  };

  return (
    <div id="homepage" className="landingPageWrapper">
   {!inView && <nav className="navbar sticky-top header">
        <div className="navbar-brand">
          <div className="qubeLogo">
            <img src={qubeLogo} height={'30'} onClick={() => {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
          }} />
          </div>
        </div>

        <form className="form-inline">
          <div className="btn-group pull-right">
            <div id="setup_text"><a href="/setup"
              onClick={() => doTrack('Setup from qube.work', { hero: false })}
              target="'_blank'" className="btn-elps">SIGN UP</a></div>
            <div id="signin_text"><a href="/login"
              onClick={() => doTrack('Login from qube.work', { hero: false })}
              target="'_blank'" className="btn-elps">LOGIN</a></div>
          </div>
        </form>
      </nav>}

      <div className="parallaxSection parallax bg1" style={{ backgroundImage: `url(${landingHero})`}} ref={ref}>
        {/*<VisibilitySensor onChange={onChange}>*/}

        <div className="hero-text">
          <h1>Hi there!</h1>
          <p>Welcome to qube - the virtual office that enables you to work remotely.<br />
            See who's chatting with coworkers, out of the office, or on lunch break.</p>

          <div className={'mb-20'}>
            <a className="prompt-elps"
              onClick={() => doTrack('Setup from qube.work', { hero: true })}
              href="/setup" target="'_blank'">sign up</a>
            |
            <a
              className="prompt-elps"
              onClick={() => doTrack('Login from qube.work', { hero: true })}
              href="/login" target="'_blank'">login</a>
          </div>
        </div>

        {/*</VisibilitySensor>*/}
        {!showLogo && <div className={'scrollArrows'} onClick={() => {
          scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }}>
          <div id="section07">
            <a>
              <span className={'downArrow'}></span>
              <span className={'downArrow'}></span>
              <span className={'downArrow'}></span>

            </a></div>
        </div>
        }
      </div>
      <div className="parallaxSection" ref={scrollRef}>
        <div className="section pb-3 bg-white">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12 col-lg-8 section-heading">
                <h2 className="heading mb-5">Not an office replacement.<br />A better way to work.
                </h2>
                <p>qube is a virtual office that enables you to work remotely. See who's chatting
                  with
                  coworkers, out of the office, or stepped out for lunch. qube empowers virtual
                  office
                  life by helping remote workers feel connected and grounded.</p>
                <p>qube is more than just a replacement for physical walls. It’s a modern approach
                  to a
                  clean, personalizable and intuitive workspace that is truly joyful to work in.
                </p>

              </div>
            </div>
          </div>
        </div>

        <div className="section bg-white pt-2 pb-2 text-center">
          <p>
            <img src={landingImage01} alt="Image" className="img-fluid" />
          </p>
        </div>


        <div className="section">
          <div className="container">
            <div className="row section-heading justify-content-center mb-5">
              <div className="col-md-8 text-center">
                <h2 className="heading mb-3">Features you didn't even know you wanted.</h2>
                {/*<p className="sub-heading mb-5">features you didn't even know you wanted</p>*/}
              </div>
            </div>
            <div className="row">
              {/* watching start */}
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="media feature-icon d-block text-center">
                  <div className="icon">
                    <span className="fa fa-eye"></span>
                  </div>
                  <div className="media-body">
                    <h3>Watching</h3>
                    <p>You know how some people always seem to be in meetings? Some of us have
                      acquired
                      the skill of pouncing exactly in between meetings. For everyone else,
                      we’ve
                      added the ability to “watch” people and be notified when they’re
                      back.</p>
                  </div>
                </div>
              </div>
              {/* watching end */}

              {/* customize start */}
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="media feature-icon d-block text-center">
                  <div className="icon">
                    <span className="fa fa-home"></span>
                  </div>
                  <div className="media-body">
                    <h3>Make it yours</h3>
                    <p>It’s your qube after all. Not too fond of our default color scheme? Well,
                      maybe
                      you prefer Darcula or Popsicle?<br />
                      Let others know how you feel by selecting an emotion as well as
                      changing your avatar color.</p>
                  </div>
                </div>
              </div>
              {/* customize end */}

              {/* instant meetings */}
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="media feature-icon d-block text-center">
                  <div className="icon">
                    <span className="fa fa-user-plus"></span>
                  </div>
                  <div className="media-body">
                    <h3>Meetings are a double-click away</h3>
                    <p>Start meetings seamlessly. Double click to knock on someone’s office.
                      Once
                      your
                      knock is accepted,
                      you’ll automatically be pulled into their office and an instant Zoom
                      meeting will start! Or invite someone to an ongoing meeting.</p>
                  </div>
                </div>
              </div>
              {/* instant meetings end */}


              {/* slack */}
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="media feature-icon d-block text-center">
                  <div className="icon">
                    <span className="fab fa-slack"></span>
                  </div>
                  <div className="media-body">
                    <h3>The Power of Slack</h3>
                    <p>Forgot to update your qube status before
                      stepping away? Update your status through Slack
                      (<code>/qube emergency ice cream break</code>).<br />

                      Need to Slack everyone in your office with notes
                      at the end of a productive meeting? You can!
                    </p>
                  </div>
                </div>
              </div>
              {/* slack end */}


              {/* guest */}
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="media feature-icon d-block text-center">
                  <div className="icon">
                    <span className="fa fa-crown"></span>
                  </div>
                  <div className="media-body">
                    <h3>Synchronized Meetings</h3>
                    <p>Import scheduled Zoom meetings. For executives or sales staff especially!
                      When
                      you
                      start a scheduled a Zoom meeting with a (potential) customer, qube will
                      automatically update your state to ‘busy’ and your status with relevant
                      details.
                    </p>
                  </div>
                </div>
              </div>
              {/* guest end */}

              {/* timezones */}
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="media feature-icon d-block text-center">
                  <div className="icon">
                    <span className="far fa-clock"></span>
                  </div>
                  <div className="media-body">
                    <h3>Intelligent timezones</h3>
                    <p>Working remotely is awesome! Constantly
                      converting time zones isn’t - that's why we
                      display all times converted to your local timezone.<br />
                      For the wanderers and world travelers!
                      qube will auto-detect and update your timezone for your
                      convenience.</p>
                  </div>
                </div>
              </div>
              {/* timezone end */}
            </div>
          </div>
        </div>


        <div className="section">
          <div className="container">
            <div className="row section-heading justify-content-center mb-5">
              <div className="col-md-8 text-center">
                <h2 className="heading mb-3">Express yourself.</h2>
                <p className="sub-heading mb-5">Life's too short to read boring qube statuses.</p>
              </div>
            </div>
            <div className="row">

              <div className="ftco-46 width-maximum">
                <div className="ftco-46-row d-flex flex-column flex-lg-row">
                  <div className="ftco-46-image"
                    style={{ backgroundImage: 'url(\'' + landingImg_1 + '\')' }} />
                  <div className="ftco-46-text ftco-46-arrow-left">

                    <h4 className=" ftco-46-subheading">No Growing pains</h4>
                    <h3 className=" ftco-46-heading">qube grows as you grow</h3>
                    <p className=" mb-5">No moving boxes required.<br />You can even pin
                      co-workers
                      who
                      have moved a few too many virtual clicks away.</p>
                    {/*<p><a href="#" className="btn-link">Learn More <span*/}
                    {/*    className="ion-android-arrow-forward"></span></a></p>*/}
                  </div>
                  <div className="ftco-46-image"
                    style={{ backgroundImage: 'url(\'' + landingImg_2 + '\')' }} />
                </div>

                <div className="ftco-46-row d-flex flex-column flex-lg-row">
                  <div className="ftco-46-text ftco-46-arrow-right">
                    <h4 className="ftco-46-subheading">Room Names</h4>
                    <h3 className="ftco-46-heading">It's more than just a room name</h3>
                    <p className="mb-5">The possibilities are endless. Be creative, express
                      yourself.</p>
                    {/*<p><a href="#" className="btn-link">Learn More <span*/}
                    {/*    className="ion-android-arrow-forward"></span></a></p>*/}
                  </div>
                  <div className="ftco-46-image"
                    style={{ backgroundImage: 'url(\'' + landingImg_3 + '\')' }} />
                  <div className=" ftco-46-text ftco-46-arrow-up">
                    <h4 className="ftco-46-subheading">Back By Reminders</h4>
                    <h3 className="ftco-46-heading">Running a bit later than expected?</h3>
                    <p className="mb-5">Our Slack reminders can be quite convincing.</p>

                    {/*<p><a href="" className=" btn-link">Learn More <span*/}
                    {/*    className=" ion-android-arrow-forward"></span></a>*/}
                    {/*</p>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <footer className="ftco-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mb-5">
                <div className="footer-widget">
                  <h3 className="mb-4">Built by remote workers for remote workers.</h3>
                  <p>Questions? Opinions? Suggestions?<br />
                    Send them our way - we'd love to hear! </p>

                  <div>

                    <div id="emailUs"><a href="mailto:qube@seeq.com" target="'_blank'"
                      onClick={() => doTrack('Email us clicked on qube.work')}
                      className="btn-elps">EMAIL US</a></div>

                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="footer-widget">
                  <h3 className="mb-4">Follow Along</h3>
                  <ul className="list-unstyled socialLinks">
                    <li><a href="https://www.linkedin.com/company/qube-work"
                      onClick={() => doTrack('Click on Social Media Link', { target: 'linkedIn' })}
                      target="_blank"><span
                      className="fa fab fa-linkedin"></span></a></li>
                    <li><a href="https://twitter.com/QubeWork"
                      onClick={() => doTrack('Click on Social Media Link', { target: 'twitter' })}
                      target="_blank"><span
                      className="fa fab fa-twitter"></span></a></li>
                    <li><a href="https://www.facebook.com/qubework-112449653764186"
                      onClick={() => doTrack('Click on Social Media Link', { target: 'facebook' })}
                      target="_blank"><span
                      className="fa fab fa-facebook"></span></a></li>
                    <li><a href="https://medium.com/@qube.work"
                      onClick={() => doTrack('Click on Social Media Link', { target: 'medium' })}
                      target="_blank"><span
                      className="fa fab fa-medium"></span></a></li>
                  </ul>
                </div>
                <div className="footer-widget">
                  <h3 className="mb-4">Newsletter</h3>
                  <form onSubmit={subscribeToNewsletter} className="ftco-footer-newsletter">
                    <div className="form-group">
                      <button onClick={subscribeToNewsletter} className="button"><span
                        className="fa fa-envelope"></span>
                      </button>
                      <input type="email" className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder="Enter Email" />
                    </div>
                    {newsletterSuccess &&
                    <p>Thanks for your interest! You have successfully subscribed to our
                      newsletter.</p>}
                    {newsletterError &&
                    <p>{errorMessage}</p>}
                  </form>
                </div>
              </div>

            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default HomePage;
